<template>
  <b-alert
    v-model="isSnackbarVisible"
    :variant="status"
    dismissible
    fade
    class="snackbar-alert"
  >
    <div class="row">
      <span class="col-12">{{ content }}</span>
      <ul class="col-12">
        <span v-if="typeof errors === 'string'">{{ errors }}</span>
        <div v-else>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </div>
      </ul>
    </div>
  </b-alert>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'snackbar',
  data: () => ({
    isSnackbarVisible: false,
  }),
  computed: {
    ...mapState({
      content: (state) => state.snackbar.content,
      status: (state) => state.snackbar.status,
      errors: (state) => state.snackbar.errors,
    }),
  },
  mounted() {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'SHOW_SNACKBAR') this.isSnackbarVisible = true
      setTimeout(() => {
        this.isSnackbarVisible = false;
      }, 5000);
    })
  },
}
</script>

<style>
.alert {
  position: fixed !important;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1050;
  width: 30%;
}
@media (max-width: 767.98px) {
  .alert {
    width: 70%;
  }
}
</style>